import React from 'react'

function SectionA() {
  return (
    <div className='max-w-[680px] mx-auto'>
      <h1 className='font-satoshi font-black text-center text-[42px] leading-[50.4px] mb-[35px]'></h1>
      <div class='cellphone-frame mx-auto'>
        <img src='/img/left-cell.svg' alt='' class='left' />
        <img src='/img/center-cell.svg' alt='' class='center' />
        <img src='/img/right-cell.svg' alt='' class='right' />
      </div>

      <div className='max-w-[545px] mx-auto'>
        <h2 className='font-satoshi font-black text-center leading-[41.6px] text-[32px] mb-[35px]'>
          <span className='text-pink'>Artichoke:</span> Empower Your
          <span className='text-pink uppercase'> Students</span> with
          Artichoke's Digital Cards.
        </h2>
        <p className='max-w-[379px] text-center font-inter font-medium mx-auto text-xl'>
          Create Your Digital Student Card Now, And Grow Your Network
        </p>
      </div>
      <div className='w-fit mx-auto mt-5 pb-[86px]'>
        <a href='https://app.artichokeapp.com/login' target='_blank'>
          <button className='bg-pink text-white px-10 py-5 rounded-2xl flex items-center'>
            Get Started
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='8'
              height='14'
              viewBox='0 0 8 14'
              fill='none'
              className='ml-4'
            >
              <path
                d='M0.220002 13.78C0.150338 13.7104 0.0950702 13.6278 0.0573629 13.5369C0.0196556 13.4459 0.000247372 13.3484 0.000247376 13.25C0.00024738 13.1515 0.0196556 13.0541 0.0573629 12.9631C0.0950703 12.8722 0.150338 12.7896 0.220002 12.72L5.939 6.99999L0.220002 1.27999C0.150402 1.21039 0.0951914 1.12776 0.0575237 1.03682C0.0198559 0.945887 0.000468217 0.848421 0.000468221 0.749991C0.000468226 0.651561 0.019856 0.554094 0.0575237 0.463157C0.0951915 0.372219 0.150402 0.289591 0.220002 0.21999C0.289603 0.15039 0.372231 0.09518 0.463168 0.0575124C0.554106 0.0198449 0.651572 0.000457452 0.750002 0.000457458C0.848432 0.000457463 0.945898 0.0198449 1.03684 0.0575125C1.12777 0.09518 1.2104 0.15039 1.28 0.21999L7.53 6.46999C7.59967 6.53956 7.65493 6.62217 7.69264 6.71311C7.73035 6.80406 7.74976 6.90154 7.74976 6.99999C7.74976 7.09844 7.73035 7.19593 7.69264 7.28687C7.65493 7.37781 7.59967 7.46043 7.53 7.52999L1.28 13.78C1.21044 13.8497 1.12782 13.9049 1.03688 13.9426C0.945936 13.9803 0.848451 13.9997 0.750001 13.9997C0.651552 13.9997 0.554067 13.9803 0.463125 13.9426C0.372182 13.9049 0.289566 13.8497 0.220002 13.78Z'
                fill='white'
              />
            </svg>
          </button>
        </a>
      </div>
    </div>
  )
}

export default SectionA
