import React from 'react'

function SectionD() {
  return (
    <div className='max-w-[934px] mx-auto'>
      <div class='grid grid-cols-5 items-center gap-36'>
        <div className='col-span-3'>
          <h1 className='text-[32px] leading-[38px] font-black font-satoshi'>
            A<span class='text-pink'> Sustainable</span> Solution,
          </h1>
          <p class='text-xl mt-6'>
            An Eco-Friendly Solution, Share Faith Without Consuming Physical
            Resources.
          </p>
        </div>
        <div className='col-span-2'>
          <img src='/img/global.svg' alt='' />
        </div>
      </div>
    </div>
  )
}

export default SectionD
