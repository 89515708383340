import Navbar from './sections/navbar'
import SectionA from './sections/section-a'
import SectionB from './sections/section-b'
import SectionC from './sections/section-c'
import SectionD from './sections/section-d'
import SectionE from './sections/section-e'
import SectionF from './sections/section-f'

function App() {
  return (
    <div>
      <div className='bg-[#F8F8F8] pt-[78px]'>
        <Navbar />
        <div className='mt-[84px]'>
          <SectionA />
        </div>
      </div>
      <div className='mt-[81px]'>
        <SectionB />
      </div>
      <SectionC />
      <div className='mt-[107px] mb-[109px]'>
        <SectionD />
      </div>
      <div className='bg-[#F7F7F7]'>
        <SectionE />
      </div>
      <div className='mt-44 '>
        <SectionF />
      </div>
    </div>
  )
}

export default App
