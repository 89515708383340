import React from 'react'

function SectionB() {
  return (
    <div>
      <h2 className='font-satoshi font-black text-[36px] leading-[43px] max-w-[563px] mx-auto'>
        Never lose your Student Card Never{' '}
        <span className='uppercase text-pink'>LOSE</span> an{' '}
        <span className='uppercase text-pink'>OPPORTUNITY</span>
      </h2>
      <div className='max-w-[651px] mx-auto'>
        <p className='text-center mt-6 text-xl'>
          Add your Digital Student card to your Digital Apple & Google Wallet.
          Carry your card with you all the time and everywhere
        </p>
      </div>
      <div className='max-w-[920px] mx-auto grid grid-cols-5 items-center mt-20'>
        <div className='col-span-3'>
          <h3 className='font-satoshi font-black text-[32px] leading-[38.4px]'>
            No <span className='text-pink'>App</span>, No{' '}
            <span className='text-pink'>Internet</span> Required
          </h3>
          <p className='mt-6 text-xl max-w-[496px] '>
            Add your Digital card directly to your Apple & Google Wallet. <br />
            <br />
            Share your contact details offline with a single scan and expedite
            network growth.
          </p>
          <div className='flex gap-3 mt-9 mb-16 '>
            <img src='/img/apple-wallet.svg' alt='apple wallet' />
            <img src='/img/google-wallet.svg' alt='apple wallet' />
          </div>
        </div>
        <div className='col-span-2'>
          <div>
            <img
              src='/img/Points-Cards-and-Membership-Cards.webp'
              className=''
              alt='side-cell'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionB
