import React from 'react'

function Item({ icon, heading, description }) {
  return (
    <div className='text-center'>
      <img src={icon} alt='network' className='mx-auto' />
      <div className='font-satoshi font-black mt-[29px] text-xl'>{heading}</div>
      <p className='mt-[21px] text-sm'>{description}</p>
    </div>
  )
}

function SectionE() {
  return (
    <div className='pt-32 pb-28'>
      <div className='max-w-[579px] mx-auto text-center'>
        <h3 className='font-satoshi font-black text-[36px] leading-[43.2px]'>
          Go <span className='text-pink'>Beyond</span> Networking
        </h3>
        <p className='text-xl mt-6'>
          Enhance student engagement with dynamic digital cards and meaningful
          connections.
        </p>
      </div>
      <div className='max-w-[1105px] mx-auto grid grid-cols-2 mt-[69px] gap-9'>
        <div className='col-span-1'>
          <Item
            icon={'/img/networking/icon01.svg'}
            heading={'No Apps Required'}
            description={
              'Save your digital  card easily into your digital Apple & Google wallet'
            }
          />
        </div>
        <div className='col-span-1'>
          <Item
            icon={'/img/networking/icon02.svg'}
            heading={'Simple & User-Friendly'}
            description={
              'Create a digital  card with a QR code- powered for free in a few minutes without any tech or designing experience'
            }
          />
        </div>
        <div className='col-span-1'>
          <Item
            icon={'/img/networking/icon03.svg'}
            heading={'Remote Updates'}
            description={
              'with real-time control, create new cards for your team and update their information remotely anytime'
            }
          />
        </div>
        <div className='col-span-1'>
          <Item
            icon={'/img/networking/icon04.svg'}
            heading={'Cost-Effective'}
            description={
              'Between material, design, and shipping costs, going digital can save as much as 60%'
            }
          />
        </div>
        <div className='col-span-1'>
          <Item
            icon={'/img/networking/icon05.svg'}
            heading={'Multiple Design, For Multiple Occasions'}
            description={
              'With multiple designs feature, you can elevate your corporate brand identity and keep up with the (Days Of The Year Calendar) and special events)'
            }
          />
        </div>

        <div className='col-span-1'>
          <Item
            icon={'/img/networking/icon08.svg'}
            heading={'Increase your brand reach'}
            description={
              'Increase your brand reach (Create digital  card for every employee, turn every interaction to a branded opportunity'
            }
          />
        </div>
        <div className='hidden md:block col-span-1'></div>
        <div className='col-span-1'>
          {/* <Item
            icon={'/img/networking/icon09.svg'}
            heading={'White Labeled'}
            description={
              "make it yours and add your business logo to Cardly's portal"
            }
          /> */}
        </div>
        <div className='col-span-1'>
          {/* <Item
            icon={'/img/networking/icon10.svg'}
            heading={'Seamless Integration'}
            description={
              'Supports integration with various HR/ERP systems to automatically create, delete, and update cardholders’ information)'
            }
          /> */}
        </div>
      </div>
    </div>
  )
}

export default SectionE
