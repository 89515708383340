import React, { useEffect, useState } from 'react'

function NavItem({ label, path, active }) {
  return (
    <li
      className={`${
        active
          ? 'after:h-[2px] after:md:w-1/2 after:block after:bg-yellow after:mt-1'
          : ''
      } cursor-pointer`}
    >
      <a href={path} className='block py-2 px-3 md:p-0 text-purple'>
        {label}
      </a>
    </li>
  )
}

function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeightThreshold = 70
      setIsScrolled(window.scrollY > scrollHeightThreshold)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <nav className='border-gray-200 fixed w-full top-0 bg-transparent'>
      <div className='flex justify-between items-center p-4'>
        {/* Left side - Artichoke Inc. text */}
        <div className='flex items-center'>
          <a
            href='#'
            className='flex items-center space-x-2 rtl:space-x-reverse'
          >
            <span
              className='text-xl font-satoshi font-extrabold'
              style={{ color: '#1d2d58' }}
            >
              Artichoke
            </span>
            <span
              className='text-xl font-satoshi font-bold'
              style={{ color: '#F0414D' }}
            >
              Inc.
            </span>
          </a>
        </div>

        {/* Right side - App button */}
        <div>
          <a
            id='sign-in-desktop'
            href='https://app.artichokeapp.com/login'
            className='flex justify-center items-center px-6 py-2 rounded-lg border border-[#f35658] text-[#f35658] font-medium text-base'
            style={{
              fontFamily: 'Inter',
            }}
          >
            App
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
