import React from 'react'

function SectionC() {
  return (
    <div className='bg-yellow w-full py-24'>
      <h1 className='max-w-[779px] mx-auto text-[42px] leading-[63px] font-black font-satoshi text-center'>
        Created for <span class='text-pink'>All</span>, From Individual Members{' '}
        <br />
        to the Whole Church Community.
      </h1>
    </div>
  )
}

export default SectionC
